<template>
  <van-uploader v-model="fileList" :after-read="afterRead" />
</template>

<script>
import { Uploader } from "vant";
export default {
  components: { [Uploader.name]: Uploader },
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    afterRead(file) {
      console.log(file);
      file.status = "uploading";
      file.message = "上传中...";
      this.$sapi
        .uploadFile(file.file)
        .then((data) => {
            file.status = "success";
            alert(JSON.stringify(data));
        })
        .catch((err) => {
          file.status = "failed";
          file.message = "上传失败";
        });
    },
  },
};
</script>


<style>
</style>